<template>
    <div class="container-xl" v-if="!$store.getters.isForbidden">
      <div class="card">
        <div class="card-header border-0 justify-content-between">
          <div class="card-title">
            Missions List
          </div>
          <div class="flex flex-row mb-2 pr-2 float-right">
            <!-- <el-select size="small" v-model="merchant_id" placeholder="Merchant" class="mr-2" filterable remote :remote-method="searchMerchant">
              <el-option
                v-for="item in merchantList"
                :key="item.merchant_id"
                :label="item.merchant_name"
                :value="item.merchant_id">
              </el-option>
            </el-select> -->
            <el-button type="primary" @click="doShowModalCreate" size="small"><font-awesome-icon icon="plus"/>&nbsp; Create New Mission</el-button>
          </div>
        </div>
        <div class="card-table table-responsive">
          <!-- <div class="flex flex-row-reverse mb-2 pr-2">
            <el-button @click="searchHandler" size="small">Search</el-button>
            <el-select size="small" v-model="mission_id" placeholder="Merchant" class="mr-2" filterable remote :remote-method="searchMerchant">
              <el-option
                v-for="item in dataList"
                :key="item.mission_id"
                :label="item.merchant_name"
                :value="item.mission_id">
              </el-option>
            </el-select>
          </div> -->
          <table class="table table-vcenter">
            <thead>
              <tr>
                <th width="30%">Name</th>
                <th>Type</th>
                <th>Start at</th>
                <th>End at</th>
                <th>Point</th>
                <th>Number -n</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody v-loading="isLoading">
              <tr v-for="(item, i) in list" :key="i">
                <td v-if="item.source_type == 'other' " class="text-nowrap text-muted">{{ item.other_name }}</td>
                <td v-else-if="item.source_type == 'academic' " class="text-nowrap text-muted">{{ item.academic_name }}</td>
                <td v-else-if="item.source_type == 'social' " class="text-nowrap text-muted">{{ item.social_name }}</td>
                <td v-else-if="item.source_type == 'financial' " class="text-nowrap text-muted">{{ item.financial_name }}</td>
                <td v-else-if="item.source_type == 'wa' " class="text-nowrap text-muted">{{ item.wa_name }}</td>
                <td v-else-if="item.source_type == 'refer' " class="text-nowrap text-muted">{{ item.reference_name }}</td>
                <td v-else-if="item.source_type == 'activities' " class="text-nowrap text-muted">{{ item.activities_name }}</td>
                <td v-else class="text-nowrap text-muted">unknown</td>

                <td class="text-nowrap text-muted">{{ item.source_type }}</td>
                <td class="text-nowrap text-muted">{{ item.start_at_str }}</td>
                <td class="text-nowrap text-muted">{{ item.end_at_str }}</td>
                <td class="td-truncate">
                  <div class="text-truncate">
                    {{ item.mission_point }}
                  </div>
                </td>
                <td>{{ item.mission_sort }}</td>
                <td><span class="tag" :class="item.status_color_str">{{ item.status_str }}</span></td>
                <td>
                  <button class="btn btn-secondary btn-sm dropdown-toggle" data-toggle="dropdown">Actions</button>
                  <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow p-2" style="width: 200px">
                    <a class="dropdown-item btn-sm cursor-pointer" @click="doShowModalEdit(item)"><font-awesome-icon class="text-primary" icon="pen"/>&nbsp; Edit Mission</a>
                    <a class="dropdown-item btn-sm cursor-pointer" @click="confirmDeleteData(item.mission_id)"><font-awesome-icon class="text-danger" icon="trash"/>&nbsp; Delete Mission</a>
                    <a class="dropdown-item btn-sm cursor-pointer" @click="confirmActivateData(item)"><font-awesome-icon class="text-yellow" icon="power-off"/>&nbsp; Enable/Disable Mission</a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <el-empty description="No data found" v-if="total_rows == 0" :image="emptyStateImage"></el-empty>
        </div>
        <div class="card-footer d-flex justify-content-between">
          <small>Showing {{ showingFrom }} to {{ showingUntil }} of {{ total_rows }} entries</small>
          <div class="inline-table">
            <b-pagination
              class="table-cell"
              @change="pageChangeHandler"
              :per-page="per_page"
              :limit="5"
              :total-rows="total_rows"
              v-model="page"
            />
          </div>
        </div>
        <b-modal v-model="showModalCreate" :title="modalTitle" hide-footer>
          <b-form @submit.prevent="createNewMissions" @reset="closeModalCreate">
            <b-form-group label="Mission Type">
              <el-select @change="changeType()"
                class="w-full"
                v-model="v$.form.source_type.$model"
                placeholder="select or search mission">
                <el-option
                  v-for="(item, index) in types"
                  :value="item.value"
                  :key="index"
                  :label="item.label"/>
              </el-select>
              <b-form-invalid-feedback :state="!v$.form.source_type.$invalid" v-if="v$.form.source_type.$errors[0]">
                <span> {{ v$.form.source_type.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label="Source">
              <el-select :disabled="disabled"
                class="w-full"
                v-model="v$.form.source_id.$model"
                placeholder="select or search source"
                v-loading="isLoadingSource"
                filterable remote :remote-method="searchSource">
                <el-option v-for="(item, index) in sourceList" :value="item.source_id" :key="index" :label="item.source_name"/>
              </el-select>
              <b-form-invalid-feedback :state="!v$.form.source_id.$invalid" v-if="v$.form.source_id.$errors[0]">
                <span> {{ v$.form.source_id.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label="Rewards Start Date">
              <el-date-picker
                :picker-options="datePickerOptions"
                class="w-full"
                v-model="v$.form.start_at.$model"
                type="date"
                placeholder="Pick start date"
                format="dd MMMM yyyy"
                value-format="yyyy-MM-dd"/>
              <b-form-invalid-feedback :state="!v$.form.start_at.$invalid" v-if="v$.form.start_at.$error">
                <span v-if="v$.form.start_at.$errors[0].$validator !== 'minValue'"> {{ v$.form.start_at.$errors[0].$message }}</span>
                <span v-else> Value must below {{ moment(v$.form.end_at.$model).format('YYYY-MM-DD') }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Promo End Date">
              <el-date-picker
                :picker-options="datePickerOptions"
                class="w-full"
                v-model="v$.form.end_at.$model"
                type="date"
                placeholder="Pick start date"
                format="dd MMMM yyyy"
                value-format="yyyy-MM-dd"/>
              <b-form-invalid-feedback :state="!v$.form.end_at.$invalid" v-if="v$.form.end_at.$error">
                <span v-if="v$.form.end_at.$errors[0].$validator !== 'minValue'"> {{ v$.form.end_at.$errors[0].$message }}</span>
                <span v-else> Value must above {{ moment(v$.form.start_at.$model).format('YYYY-MM-DD') }}</span>
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label="Point">
              <b-input
                v-model="v$.form.mission_point.$model"
                placeholder="Input point for mission"
                type="number"/>
              <b-form-invalid-feedback :state="!v$.form.mission_point.$invalid" v-if="v$.form.mission_point.$errors[0]">
                <span> {{ v$.form.mission_point.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label="Sort">
              <b-input
                v-model="v$.form.mission_sort.$model"
                placeholder="Input sort for mission"
                type="number"/>
              <b-form-invalid-feedback :state="!v$.form.mission_sort.$invalid" v-if="v$.form.mission_sort.$errors[0]">
                <span> {{ v$.form.mission_sort.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>

            <div class="d-flex flex-row float-right">
              <el-button @click="confirmCreateMission" :loading="loading.create_data" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
              <el-button @click="closeModalCreateMission" size="small" class="mr-2">Cancel</el-button>
              <!-- <b-button class="mr-2" size="sm" @click="closeModalGiveVoucher" type="reset" variant="secondary">Cancel</b-button> -->
            </div>
          </b-form>
        </b-modal>
      </div>
    </div>
</template>
<script>
import moment from 'moment';
import useVuelidate from '@vuelidate/core';
import { required, requiredIf } from '@vuelidate/validators';
import {
  GET_MISSIONS,
  POST_MISSIONS,
  UPDATE_DATA,
  DELETE_DATA,
} from '@/store/modules/missions';
import emptyStateImage from '@/assets/images/empty-state.png';
import popupErrorMessages from '@/library/popup-error-messages';
import { GET_ACADEMYS } from '@/store/modules/academys';
import { GET_SOCIALS } from '@/store/modules/socials';

export default {
  name: 'Missions',
  metaInfo: {
    title: 'Missions',
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      emptyStateImage,
      dataList: [],
      mission_id: '',
      filter_merchant_loading: false,
      per_page: 20,
      total_rows: 0,
      page: 1,
      list: [],
      isLoading: true,
      loader: null,
      search_keyword: '',
      search_by: '',
      search_merchant_keyword: '',
      modalTitle: this.modalAction === 'add' ? 'Create new Mission' : 'Edit Mission',
      showModalCreate: false,
      moment,
      isLoadingVoucher: false,
      isLoadingSource: false,
      sourceList: [],
      disabled: false,
      source: {
        search_by: '',
        search_keyword: '',
      },
      loading: {
        create_data: false,
      },
      form: {
        source_type: '',
        start_at: moment().format('YYYY-MM-DD'),
        end_at: moment().add(7, 'days').format('YYYY-MM-DD'),
        source_id: '',
        mission_point: '',
        mission_sort: '',
        mission_status: 1,
      },
      datePickerOptions: {
        disabledDate: (date) => moment(date).isBefore(moment(), 'days'),
      },
      types: [{
        value: 'academic',
        label: 'Academic',
      }, {
        value: 'activities',
        label: 'Activities',
        disabled: true,
      }, {
        value: 'financial',
        label: 'Financial',
      }, {
        value: 'refer',
        label: 'Reference',
      }, {
        value: 'social',
        label: 'Social',
      }, {
        value: 'wa',
        label: 'Whatsapp',
      }, {
        value: 'other',
        label: 'Other',
      }],
    };
  },
  validations() {
    return {
      form: {
        source_type: {
          required,
        },
        source_id: {
          // required,
          required: requiredIf(this.form.source_type === 'academic' || this.form.source_type === 'social'),
        },
        start_at: {
          required,
          minValue: (val) => moment(val).isSameOrBefore(this.form.end_at),
        },
        end_at: {
          required,
          minValue: (val) => moment(val).isSameOrAfter(this.form.start_at),
        },
        mission_point: {
          required,
        },
        mission_sort: {
          required,
        },
      },
    };
  },
  computed: {
    showingFrom() {
      return this.total_rows ? ((this.page - 1) * this.per_page) + 1 : 0;
    },
    showingUntil() {
      if (this.total_rows) {
        if (this.list.length < this.per_page) {
          return this.total_rows;
        }
        return this.page * this.per_page;
      }
      return 0;
    },
  },
  async mounted() {
    const loader = this.$loading.show();
    await this.getList();
    loader.hide();
  },
  methods: {
    pageChangeHandler(page) {
      this.page = page;
      this.getList();
    },
    searchHandler() {
      this.page = 1;
      if (this.mission_id !== null) {
        this.search_by = 'mission_id';
        this.search_keyword = this.mission_id;
      }
      this.getList();
    },
    async getList() {
      this.isLoading = true;
      await this.$store.dispatch(GET_MISSIONS, {
        page: this.page,
        per_page: this.per_page,
        search_by: this.search_by,
        search_keyword: this.search_keyword,
      }).catch(() => {});
      const { rows, count } = this.$store.getters.missions;
      this.total_rows = count || 0;
      this.list = rows;
      this.isLoading = false;
    },
    doShowModalCreate() {
      this.v$.form.$touch();
      this.modalType = 'add';
      this.showModalCreate = true;
    },
    closeModalCreate() {
      this.showModalCreate = false;
    },
    searchSource(query) {
      this.timeout = setTimeout(() => {
        this.source.search_keyword = query;
        switch (this.form.source_type) {
          case 'academic':
            this.source.search_by = 'academic_name';
            this.getAcademyList();
            break;
          default:
            this.source.search_by = 'social_name';
            this.getSociaList();
        }
      }, 1000);
    },
    confirmCreateMission() {
      if (this.modalType === 'edit') {
        this.doUpdateData();
        return;
      }
      this.$confirm('You will create a new Mission. Make sure you have input data correctly. Continue?', 'Confirmation', {
        type: 'info',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            this.doCreateSocials();
          }
          done();
        },
      }).catch(() => {});
    },
    closeModalCreateMission() {
      this.form = {
        name: '',
        source_type: '',
        mission_point: '',
        mission_sort: '',
        start_at: moment().format('YYYY-MM-DD'),
        end_at: moment().add(7, 'days').format('YYYY-MM-DD'),
      };
      this.showModalCreate = false;
    },
    changeType() {
      this.form.source_id = null;
      this.sourceList = [];
      console.log(this.form.source_type);
      switch (this.form.source_type) {
        case 'academic':
          this.form.source_id = '';
          this.disabled = false;
          this.getAcademyList();
          this.academic_name = this.source_type;
          this.social_name = null;
          break;
        case 'social':
          this.form.source_id = '';
          this.disabled = false;
          this.getSociaList();
          this.academic_name = null;
          this.social_name = this.source_type;
          break;
        default:
          console.log('def');
          this.sourceList = [];
          this.form.source_id = '';
          this.disabled = true;
          this.sourceName = this.source_type;
      }
    },
    async getAcademyList() {
      this.isLoadingSource = true;
      await this.$store.dispatch(GET_ACADEMYS, {
        search_by: this.source.search_by,
        search_keyword: this.source.search_keyword,
      }).catch(() => {});
      const { rows, count } = this.$store.getters.academys;
      this.total_rows = count || 0;
      this.sourceList = rows;
      this.isLoadingSource = false;
    },
    async getSociaList() {
      this.isLoadingSource = true;
      await this.$store.dispatch(GET_SOCIALS, {
        search_by: this.source.search_by,
        search_keyword: this.source.search_keyword,
      }).catch(() => {});
      const { rows, count } = this.$store.getters.socials;
      this.total_rows = count || 0;
      this.sourceList = rows;
      console.log('act', rows);
      this.isLoadingSource = false;
    },
    async doCreateSocials() {
      this.loading.create_data = true;
      console.log(this.form);
      // /*
      await this.$store.dispatch(POST_MISSIONS, this.form)
        .then(() => {
          this.$message({
            title: 'Success',
            type: 'success',
            message: 'Mission created',
          });
          this.showModalCreate = false;
          this.getList();
        }).catch((err) => {
          popupErrorMessages(err.response.data);
        });
      // */
      this.loading.create_data = false;
    },
    doShowModalEdit(item) {
      this.form = {
        id: item.mission_id,
        source_type: item.source_type,
        start_at: moment(item.start_at).format('YYYY-MM-DD'),
        end_at: moment(item.end_at).format('YYYY-MM-DD'),
        source_id: item.source_id,
        mission_point: item.mission_point,
        mission_sort: item.mission_sort,
      };
      switch (item.source_type) {
        case 'academic':
          this.disabled = false;
          this.getAcademyList();
          this.academic_name = this.source_type;
          this.social_name = null;
          break;
        case 'social':
          this.disabled = false;
          this.getSociaList();
          this.academic_name = null;
          this.social_name = this.source_type;
          break;
        default:
          console.log('def');
          this.sourceList = [];
          this.form.source_id = '';
          this.disabled = true;
          this.sourceName = this.source_type;
      }
      this.v$.form.$touch();
      this.modalType = 'edit';
      this.showModalCreate = true;
    },
    async doUpdateData() {
      this.$confirm('You will update this data. Make sure you have input data correctly. Continue?', 'Confirmation', {
        type: 'info',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            done();
            this.loading.create_academy = true;
            await this.$store.dispatch(UPDATE_DATA, this.form)
              .then(() => {
                this.$message({
                  title: 'Success',
                  type: 'success',
                  message: 'Data updated',
                });
                this.showModalCreate = false;
                this.getList();
              }).catch((err) => {
                popupErrorMessages(err.response.data);
              });
            this.loading.create_data = false;
            return;
          }
          done();
        },
      }).catch(() => {});
    },
    async confirmDeleteData(id) {
      this.$confirm('You will delete this data. Continue?', 'Confirmation', {
        type: 'info',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            done();
            instance.confirmButtonLoading = true;
            await this.$store.dispatch(DELETE_DATA, id)
              .then(() => {
                this.$message({
                  title: 'Success',
                  type: 'success',
                  message: 'Data deleted',
                });
                this.getList();
              }).catch((err) => {
                popupErrorMessages(err.response.data);
              });
            instance.confirmButtonLoading = false;
            return;
          }
          done();
        },
      }).catch(() => {});
    },
    async confirmActivateData(item) {
      this.form = {
        id: item.mission_id,
        mission_status: 1,
      };
      this.$confirm('You will activate this data. Continue?', 'Confirmation', {
        type: 'info',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            done();
            instance.confirmButtonLoading = true;
            await this.$store.dispatch(UPDATE_DATA, this.form)
              .then(() => {
                this.$message({
                  title: 'Success',
                  type: 'success',
                  message: 'Data activated',
                });
                this.getList();
              }).catch((err) => {
                popupErrorMessages(err.response.data);
              });
            instance.confirmButtonLoading = false;
            return;
          }
          done();
        },
      }).catch(() => {});
    },
  },
};
</script>
